<template>
    <div class="detail relative sm:w-full md:w-full lg:w-full mx-auto self-center">
        <div class="header flex">
            <div class="back flex items-center justify-center mr-auto" @click="back">
                <feather-icon icon="ArrowLeftIcon"></feather-icon>
            </div>
            <div class="title flex items-center uppercase">Detail Payment</div>
            <div class="save flex items-center justify-center ml-auto" >
                <!-- <feather-icon icon="TrashIcon"></feather-icon> -->
            </div>
        </div>
        <div class="body sm:w-full md:w-full lg:w-full mx-auto self-center">
            <vs-table :data="data">
                <template slot-scope="{data}">
                    <vs-tr>
                        <vs-td>
                            Nama
                        </vs-td>
                        
                        <vs-td :data="data.name">
                            {{data.name}}
                        </vs-td>
                    </vs-tr>

                    <vs-tr>
                        <vs-td>
                            Email
                        </vs-td>
                        
                        <vs-td :data="data.email">
                            {{data.email}}
                        </vs-td>
                    </vs-tr>
                    
                    <vs-tr>
                        <vs-td>
                            Telepon
                        </vs-td>
                        
                        <vs-td :data="data.phone">
                            {{data.phone}}
                        </vs-td>
                    </vs-tr>
                    <vs-tr>
                        <vs-td>
                            Tanggal
                        </vs-td>
                        
                        <vs-td :data="data.created_at">
                            {{data.created_at}}
                        </vs-td>
                    </vs-tr>
                    <vs-tr>
                        <vs-td>
                            Jumlah
                        </vs-td>
                        
                        <vs-td :data="data.amount">
                            <span v-html="addSeperator(data.amount)"/>
                        </vs-td>
                    </vs-tr>
                    <vs-tr>
                        <vs-td>
                            Catatan
                        </vs-td>
                        
                        <vs-td :data="data.note">
                            <span v-html="data.note"/>
                        </vs-td>
                    </vs-tr>
                    <vs-tr>
                        <vs-td>
                            Status
                        </vs-td>
                        
                        <vs-td :data="data.status" v-if="!status_change">
                            {{getStatus(data.status)}}
                        </vs-td>
                        <vs-td v-else>
                            <vs-select v-model="data.status" >
                                <vs-select-item :key="index" :value="item.value" :text="item.text"  v-for="(item,index) in option_status" />
                            </vs-select>
                        </vs-td>
                    </vs-tr>
                    
                </template>
            </vs-table>

            <div class="vx-row mb-4" v-if="status_change">
                <div class="vx-col w-full">
                    <vs-button class="w-full" icon-pack="feather" icon="icon-check" @click.prevent="save">
                        Simpan</vs-button>
                </div>
            </div>
            <div class="vx-row mb-6">
                <div class="vx-col w-full">
                    <vs-button class="w-full" type="flat" color="dark" @click="back" icon-pack="feather"
                        icon="icon-arrow-left">
                        Kembali
                    </vs-button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        data() {
            return {
                params : '',
                data : [],
                option_status:[
                    {text:'Success',value:1},
                    {text:'Pending',value:2},
                    {text:'Unpaid',value:3},
                    {text:'Challenge',value:6},
                    {text:'Expire',value:7},
                    {text:'Fail',value:9},
                ],
                status_change: true,
            }
        },
        mounted() {
            this.loadData();
        },
        methods: {
            checkStatusChange(){
                if(this.data.status == 3){
                    this.status_change = true;
                }else{
                    this.status_change = false;
                }
            },
            back() {
                this.$router.go(-1)
            },
            getStatus(x) {
                var str = '';
                if(x == 1){
                    str = 'Success'
                }
                else if(x == 2){
                    str = 'Pending'
                }
                else if(x == 3){
                    str = 'Unpaid/waiting'
                }else if(x == 6){
                    str = 'Challange'
                }else if(x == 7){
                    str = 'Expire'
                }else if(x == 9){
                    str = 'Fail'
                }else{
                    str = ''
                }
                return str;
            },
            async loadData() {
                this.$vs.loading()
                await this.getData().then(resp => {
                    this.data = resp
                    this.checkStatusChange();
                })
                this.$vs.loading.close()
            },
            getGroupParam() {
                try {
                    this.params = this.$route.params.id
                } catch {
                    this.$router.push('/')
                }
            },
            getData() {
                this.getGroupParam()
                return new Promise((resolve, reject) => {
                    const data = {
                        id: this.params
                    }
                    this.$http({
                            url: 'v1/payment/retrieve',
                            method: 'POST',
                            data: data
                        })
                        .then(response => {
                            const data = response.data.serve
                            resolve(data)
                        })
                        .catch(error => {
                            reject(error)
                        })
                })
            },
            numberWithCommas(x) {
                return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
            },
            addSeperator(nStr) {
                nStr += '';
                var x = nStr.split('.');
                var x1 = x[0];
                var x2 = x.length > 1 ? ',' + x[1] : '';
                var rgx = /(\d+)(\d{3})/;
                while (rgx.test(x1)) {
                    x1 = x1.replace(rgx, '$1' + '.' + '$2');
                }
                return "Rp "+ x1 + x2;
            },
            save(){
                this.$vs.loading()
                const send = {
                    uuid : this.data.uuid,
                    status : this.data.status
                }
                this.$http({
                    url: 'v1/payment/update',
                    method: 'POST',
                    data: send
                })
                .then(response => {
                    this.data = response.data.serve
                    this.checkStatusChange();
                    this.$vs.loading.close()
                    this.$vs.notify({
                        title: 'Success!',
                        text: 'Saved!',
                        color: 'success',
                        position: 'top-center'
                    })
                })
                .catch(err => {
                    this.$vs.notify({
                        title: 'Oops!',
                        text: err.response ? err.response.data.message :
                            'Something wrong, ' + err,
                        color: 'danger',
                        position: 'top-center'
                    })
                    this.$vs.loading.close()
                })
            }
        }
    }
</script>

<style lang="scss" scoped>
    @import "@/assets/scss/vuesax/_variables.scss";

    .detail {
        min-height: 400px;
        background-color: #ffffff;
        box-shadow: 0 0px 20px 0 rgba(0, 0, 0, .05);
        border-radius: .5rem;
        padding-bottom: 30px;
        padding-left: 20px;
        padding-right: 20px;

        .header {
            padding-top: 20px;
            margin-bottom: 40px;

            .help-toggle {
                background: rgba(var(--vs-primary), 1) !important !important;

                span {
                    font-size: 16px;
                    color: rgba(var(--vs-primary), 1) !important !important;
                }
            }

            .back {
                width: 40px;
                height: 40px;
                background: rgba(0, 0, 0, .05);
                border-radius: 20px;
                cursor: pointer;

                .feather-icon {
                    width: 20px;
                    height: 20px;
                }
            }

            .title {
                color: #444444;
                font-weight: 600;
                font-size: 1.3rem;
                line-height: 1;
            }

            .save {
                width: 40px;
                height: 40px;
                background: #ffffff;
                border-radius: 20px;
                // cursor: ;

                .feather-icon {
                    color: $white;
                    width: 20px;
                    height: 20px;
                }
            }
        }

        .body {
            .now {
                font-size: 25px;
                text-align: center;
                margin-top: 20%;
            }

            .error-msg {
                color: $danger;
                font-size: 11px
            }

            .icons {
                width: 100%;

                li {
                    width: 40px;
                    height: 40px;
                    border-radius: 3px;
                    border: 1px solid transparent;
                    transition: all .5s;
                    cursor: pointer;

                    .feather-icon {
                        width: 20px;
                        height: 20px;
                    }

                    &:hover {
                        background: rgb(255, 255, 255);
                        border: 1px solid rgb(248, 249, 250);
                        box-shadow: 0 0px 10px 0 rgba(0, 0, 0, .05);
                    }

                    &.active {
                        background: rgba(var(--vs-primary), 1) !important;

                        .feather-icon {
                            color: $white;
                        }
                    }
                }
            }
        }
    }
</style>